import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { GenerateNoticeDialog } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/generate-notice/_index";
import { defaultMessageGenerateNotice } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/generate-notice/config";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const GenerateNoticeButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { noticeRuns } = useNoticeRunsStore();
  const [isDisableOnce, setIsDisableOnce] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();

  const actionAfterSuccessFinish = (response: any) => {
    const isSuccessResponse = response?.IsSuccess;
    setIsDisableOnce(isSuccessResponse);
    const successMessage =
      response?.SuccessMessage ?? defaultMessageGenerateNotice.success;
    const errorMessage =
      response?.ErrorMessage ?? defaultMessageGenerateNotice.error;
    pushNotification({
      title: isSuccessResponse ? successMessage : errorMessage,
      type: isSuccessResponse ? "success" : "error",
      autoClose: isSuccessResponse,
    });
  };

  const isDisable = useMemo(() => {
    return (
      isEmpty(noticeRuns?.DocumentTemplate) ||
      !isNil(noticeRuns?.ProducedOn) ||
      isDisableOnce
    );
  }, [noticeRuns?.ProducedOn, noticeRuns?.DocumentTemplate, isDisableOnce]);

  return (
    <>
      <CCNavButton
        title="Generate notice"
        onClick={() => {
          setIsShowDialog(true);
        }}
        disabled={isDisable}
      />
      {isShowDialog && (
        <GenerateNoticeDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          noticeRunId={parseInt(id)}
          actionAfterFinish={actionAfterSuccessFinish}
        />
      )}
    </>
  );
});
