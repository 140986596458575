import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IChangeOfOwnerShipButtonProps {
  componentNumber: eComponent;
  disabled?: boolean;
}
export const ChangeOfOwnerShipButton = observer(
  ({ componentNumber, disabled }: IChangeOfOwnerShipButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();
    const { gridSelectedIds, gridSelectedRows, isEmptyData } =
      useCCProductListViewStore();

    const getIdForComponent = (component: eComponent, fallbackId?: any) =>
      componentNumber === component ? id ?? fallbackId : null;

    const isDisabled = useMemo(() => !id && isEmptyData, [id, isEmptyData]);

    const handleOnClick = () => {
      const dialogProps = {
        assessmentId: getIdForComponent(eComponent.Assessment, [
          ...gridSelectedRows.map((row) => row.Assessment_Id),
        ]),
        contactId: getIdForComponent(eComponent.Entity, id),
        titleId: getIdForComponent(eComponent.Title, gridSelectedIds[0]),
        picId: getIdForComponent(eComponent.PIC, gridSelectedIds[0]),
        componentNumber: componentNumber ?? eComponent.Assessment,
      };
      setListDialog([
        {
          type: WorkflowTypes.Change_Of_Ownership,
          data: {},
          props: dialogProps,
        },
      ]);
    };

    return (
      <CCNavButton
        title={"Change of Ownership"}
        onClick={handleOnClick}
        disabled={
          disabled ??
          (isDisabled && componentNumber !== eComponent.Change_of_Ownership)
        }
      />
    );
  }
);
