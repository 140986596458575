import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { useSupplementaryRatesMasterPropertyStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/store";
import { deleteValuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/api";
import { NewValuationDialog } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/dialogs/new-valuation/_index";
import { ICallbackNextStep } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/model";
import {
  createMasterPropertyValuation,
  getMasterPropertyValuations,
  updateMasterPropertyValuation,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/api";
import { colMasterPropertyValuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/config";
import {
  DTO_Supplementary_MasterProperty_Valuation,
  DTO_ValuationValues,
  IActionLoadMPValuation,
  MasterPropertyValuation,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { formatDynamicValuationsForColumn } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export const ValuationsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfValuation =
  nameOfFactory<DTO_Supplementary_MasterProperty_Valuation>();
const nameOfValuationValue = nameOfFactory<DTO_ValuationValues>();
const FormStepElement = observer(
  ({
    formRenderProps,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    localNotificationRef,
    options = { isReadOnly: false },
    isModified = false,
    setSubAction = () => {},
    formRef,
  }: IFormStepElement) => {
    //Use ref
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    //Use store
    const { supplementaryRatesId } = useSupplementaryRatesStore();
    const { selectedMasterPropertyId: masterPropertyId } =
      useSupplementaryRatesMasterPropertyStore();

    //Use state
    const [callbackNextAction, setCallbackNextAction] =
      useState<ICallbackNextStep>();

    const [valuations, setValuations] = useState<
      DTO_Supplementary_MasterProperty_Valuation[]
    >([]);
    const [selectedValuation, setSelectedValuation] =
      useState<DTO_Supplementary_MasterProperty_Valuation>();

    const [colValuation, setColValuation] = useState<IColumnFields[]>(
      colMasterPropertyValuation ?? []
    );
    const [showNewValuationDialog, setShowNewValuationDialog] = useState(false);
    const [showConfirmDeletionDialog, setShowConfirmDeletionDialog] =
      useState<boolean>(false);
    const [isCreatingValuation, setIsCreatingValuation] =
      useState<boolean>(false);
    const [isUpdatingValuation, setIsUpdatingValuation] =
      useState<boolean>(false);
    const [isDeletingValuation, setIsDeletingValuation] =
      useState<boolean>(false);

    /**
     * Common function to load valuation list in the dropdown
     * @param successAction: callback function executed when calling API successfully
     * @param failedAction: callback function executed when calling API failed
     * @param loadingAction (optional): callback function executed to set loading state after API call finish
     */
    const loadValuations = async (
      actionLoadValuation: IActionLoadMPValuation
    ) => {
      const { successAction, failedAction, loadingAction } =
        actionLoadValuation;
      const response = await getMasterPropertyValuations(
        supplementaryRatesId,
        masterPropertyId
      );
      if (isSuccessResponse(response) && response?.data) {
        const responseViewConfig = await loadViewConfiguresColumns(
          ViewConfiguration.MasterPropertyAdjustment_Valuation,
          colMasterPropertyValuation,
          {
            dynamicFormat: formatDynamicValuationsForColumn,
          }
        );
        if (loadingAction) loadingAction();
        if (Array.isArray(responseViewConfig)) {
          setColValuation(responseViewConfig);
          successAction(response.data);
        }
      } else {
        failedAction(response);
      }
    };

    /**
     * Load view configuration and valuation list in the dropdown
     */
    const loadData = async () => {
      setIsLoadingStep(true);
      loadValuations({
        //Handle when loading valuation successfully
        successAction: (data: DTO_Supplementary_MasterProperty_Valuation[]) => {
          setValuations(data);
          setSelectedValuation(data[0]);
        },

        //Handle when loading valuation failed
        failedAction: (
          response: APIResponse<DTO_Supplementary_MasterProperty_Valuation[]>
        ) => {
          setLoadFailedStep({
            onReload: () => {
              loadData();
            },
            responseError: {
              status: response.status,
              error: response.error ?? "Load failed",
            },
          });
        },

        //Handle when loading valuation finished
        loadingAction: () => {
          setIsLoadingStep(false);
        },
      });
    };

    /**
     * Set callback to handle confirm saving valuation before moving to next action
     */
    const confirmSaveValuation = () => {
      return new Promise<void>((resolve) => {
        setCallbackNextAction({
          callBack: () => {
            resolve();
          },
        });
      });
    };

    /**
     * Resets state to control the display of the confirmation dialog.
     */
    const resetConfirmationDialogState = () => {
      // Reset subAction
      // to avoid showing confirmation dialog when clicking on "Next" or other steps
      setSubAction(undefined);

      // Reset modified state and callbackNextAction
      // to avoid showing confirmation dialog when clicking on other buttons in Valuation step
      formRef?.current && formRef.current.resetForm();
      setCallbackNextAction(undefined);
    };

    /**
     * Handle creating valuation
     * @param data
     */
    const handleCreateValuation = async (data: MasterPropertyValuation) => {
      setIsCreatingValuation(true);
      const response = await createMasterPropertyValuation(
        supplementaryRatesId ?? 0,
        masterPropertyId,
        data
      );
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        //Reload valuation list
        loadValuations({
          //Handle when loading valuation successfully
          successAction: (
            data: DTO_Supplementary_MasterProperty_Valuation[]
          ) => {
            setShowNewValuationDialog(false);
            setIsCreatingValuation(false);
            setValuations(data);
            setSelectedValuation(data[0]);
            localNotificationRef?.current?.pushNotification({
              title:
                response.data.SuccessMessage ??
                "Valuation has been created successfully.",
              type: "success",
            });
          },

          //Handle when loading valuation failed
          failedAction: (
            response: APIResponse<DTO_Supplementary_MasterProperty_Valuation[]>
          ) => {
            setLoadFailedStep({
              onReload: () => {
                loadData();
              },
              responseError: {
                status: response.status,
                error: response.error ?? "Load failed",
              },
            });
          },
        });
      } else {
        setIsCreatingValuation(false);
        //Local notification
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? "Create valuation failed",
          type: "error",
        });
      }
    };

    /**
     * Handle creating valuation
     * @param data
     */
    const handleDeleteValuation = async () => {
      setIsDeletingValuation(true);
      const response = await deleteValuation(selectedValuation?.Id ?? 0);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        //Reload valuation list
        loadValuations({
          //Handle when loading valuation successfully
          successAction: (
            data: DTO_Supplementary_MasterProperty_Valuation[]
          ) => {
            setShowConfirmDeletionDialog(false);
            setIsDeletingValuation(false);
            setValuations(data);
            setSelectedValuation(data[0]);
            localNotificationRef?.current?.pushNotification({
              title:
                response.data.SuccessMessage ??
                "Valuation has been deleted successfully",
              type: "success",
            });
          },

          //Handle when loading valuation failed
          failedAction: (
            response: APIResponse<DTO_Supplementary_MasterProperty_Valuation[]>
          ) => {
            setShowConfirmDeletionDialog(false);
            setIsDeletingValuation(false);
            setLoadFailedStep({
              onReload: () => {
                loadData();
              },
              responseError: {
                status: response.status,
                error: response.error ?? "Load failed",
              },
            });
          },
        });
      } else {
        setShowConfirmDeletionDialog(false);
        setIsDeletingValuation(false);
        //Local notification
        localNotificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? "Delete valuation failed",
          type: "error",
        });
      }
    };

    /**
     * Handle updating valuation
     */
    const handleUpdateValuation = async () => {
      setIsUpdatingValuation(true);
      const response = await updateMasterPropertyValuation(
        selectedValuation?.Id ?? 0,
        selectedValuation as DTO_Supplementary_MasterProperty_Valuation
      );
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        //Reload valuation list
        loadValuations({
          //Handle when loading valuation successfully
          successAction: (
            data: DTO_Supplementary_MasterProperty_Valuation[]
          ) => {
            setIsUpdatingValuation(false);
            setValuations(data);
            setSelectedValuation(data[0]);

            //Notify updating valuation successfully
            localNotificationRef?.current?.pushNotification({
              title:
                response.data.SuccessMessage ??
                "Valuation has been updated successfully.",
              type: "success",
            });

            callbackNextAction && callbackNextAction.callBack(); //Move to next action
            resetConfirmationDialogState();
          },

          //Handle when loading valuation failed
          failedAction: (
            response: APIResponse<DTO_Supplementary_MasterProperty_Valuation[]>
          ) => {
            setLoadFailedStep({
              onReload: () => {
                loadData();
              },
              responseError: {
                status: response.status,
                error: response.error ?? "Load failed",
              },
            });
          },
        });
      } else {
        //Reset loading satte
        setIsUpdatingValuation(false);

        //Close confirmation dialog without reseting modified state
        setCallbackNextAction(undefined);

        //Notification updating failed
        localNotificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? "Update valuation failed.",
          type: "error",
        });
      }
    };

    /**
     * Handle editing valuation value
     * @param dataRow
     */
    const handleEditValuationValue = (dataRow: DTO_ValuationValues) => {
      setSubAction(confirmSaveValuation);
      const newGridData = selectedValuation?.Values?.map(
        (valuationValue: DTO_ValuationValues) => {
          if (valuationValue.Valuation_Type_Id === dataRow.Valuation_Type_Id) {
            return {
              ...dataRow,
              Valuation_Difference:
                (dataRow.Current_Valuation ?? 0) -
                (dataRow.Previous_Valuation ?? 0),
            };
          }
          return valuationValue;
        }
      );
      handleOnChangeValuationData(nameOfValuation("Values"), newGridData);
    };

    /**
     * Handle onChange for all fields
     * @param field
     * @param value
     */
    const handleOnChangeValuationData = (field: string, value: any) => {
      setSelectedValuation({
        ...selectedValuation,
        [field]: value,
      } as DTO_Supplementary_MasterProperty_Valuation);
      formRenderProps.onChange(field, { value });
    };

    //Set action to be executed before executing main step in parent
    useEffect(() => {
      if (isModified) {
        setSubAction(confirmSaveValuation);
      } else {
        setSubAction(undefined);
      }
      // eslint-disable-next-line
    }, [isModified]);

    useEffectOnce(() => {
      loadData();
      return () => {
        setSubAction(undefined);
      };
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-flex-container">
          <div className="cc-field cc-valuation-in-supp-field">
            <CCLabel title="Valuation in supplementary" />
            <CCSearchComboBox
              clearButton={false}
              data={valuations}
              textField={nameOfValuation("ItemDescription")}
              dataItemKey={nameOfValuation("Id")}
              value={selectedValuation}
              onChange={async (event: ComboBoxChangeEvent) => {
                isModified && (await confirmSaveValuation());
                setSelectedValuation(event.value);
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field cc-button-add">
            <CCLabel title="&nbsp;" />
            <Button
              type="button"
              className="cc-grid-tools-bar"
              iconClass="fas fa-plus"
              title="Add Valuation"
              onClick={async () => {
                isModified && (await confirmSaveValuation());
                setShowNewValuationDialog(true);
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="&nbsp;" />
            <Button
              type="button"
              className="cc-grid-tools-bar"
              iconClass="fas fa-minus"
              title="Remove Valuation"
              onClick={() => setShowConfirmDeletionDialog(true)}
              disabled={options?.isReadOnly || !selectedValuation}
            />
          </div>
        </div>
        {selectedValuation && (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Effective date" />
                <Field
                  name={nameOfValuation("EffectiveDate")}
                  value={selectedValuation.EffectiveDate ?? null}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={options?.isReadOnly}
                  onChange={(event: DatePickerChangeEvent) =>
                    handleOnChangeValuationData(
                      nameOfValuation("EffectiveDate"),
                      event.value
                    )
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Issue date</label>
                <Field
                  name={nameOfValuation("IssueDate")}
                  value={selectedValuation.IssueDate ?? null}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={options?.isReadOnly}
                  onChange={(event: DatePickerChangeEvent) =>
                    handleOnChangeValuationData(
                      nameOfValuation("IssueDate"),
                      event.value
                    )
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Valuation date</label>
                <Field
                  name={nameOfValuation("ValuationDate")}
                  value={selectedValuation.ValuationDate ?? null}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={options?.isReadOnly}
                  onChange={(event: DatePickerChangeEvent) =>
                    handleOnChangeValuationData(
                      nameOfValuation("ValuationDate"),
                      event.value
                    )
                  }
                />
              </div>
            </div>
            <div className="cc-field cc-form-cols-1">
              <CCGrid
                data={selectedValuation.Values ?? []}
                columnFields={colValuation}
                primaryField={nameOfValuationValue("Valuation_Type")}
                editableMode={options?.isReadOnly ? undefined : "cell"}
                onDataRowChange={handleEditValuationValue}
                itemPerPage={10}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Comment" />
              <Field
                name={nameOfValuation("Comment")}
                component={CCTextArea}
                value={selectedValuation.Comment ?? ""}
                readOnly={options?.isReadOnly}
                onChange={(event: TextAreaChangeEvent) =>
                  handleOnChangeValuationData(
                    nameOfValuation("Comment"),
                    event.value
                  )
                }
              />
            </div>
            <div
              className={"cc-dialog-footer-actions-right cc-calculate-button"}
            >
              <Button
                type="button"
                className={"cc-dialog-button"}
                onClick={async () => {
                  await confirmSaveValuation();
                }}
                disabled={options?.isReadOnly}
              >
                Calculate
              </Button>
            </div>
          </>
        )}
        {showNewValuationDialog && (
          <NewValuationDialog
            onClose={() => setShowNewValuationDialog(false)}
            onSubmit={handleCreateValuation}
            isSubmitting={isCreatingValuation}
            notificationRef={notificationRef}
          />
        )}
        {showConfirmDeletionDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you wish to remove this valuation?"}
            onClosePopup={() => {
              setShowConfirmDeletionDialog(false);
            }}
            onAsyncConfirm={async () => {
              handleDeleteValuation();
            }}
            isLoadingYes={isDeletingValuation}
          />
        )}
        {callbackNextAction && (
          <ConfirmDialog
            title={"Confirmation"}
            subMessage={
              "Valuation details have been modified. Click 'Yes' to save the changes or 'No' to discard and continue."
            }
            isLoadingYes={isUpdatingValuation}
            onClosePopup={() => {
              setCallbackNextAction(undefined); // Close confirmation dialog

              // Only reset subAction if no modifications were made
              if (!isModified) {
                setSubAction(undefined);
              }
            }}
            onAsyncConfirm={async () => {
              handleUpdateValuation();
            }}
            onConfirmNo={() => {
              callbackNextAction.callBack(); //Move to next action
              resetConfirmationDialogState();
            }}
            btnCancelVisible={false}
          />
        )}
      </section>
    );
  }
);
