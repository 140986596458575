import {
  TMappingPrivilege,
  mappingPrivilege,
} from "@app/products/property/components/action-bar/property-workflow/util";
import { IPropertyPanelBar } from "@app/products/property/components/panel-bar/_index";
import {
  EPrivilegesWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { SettingField } from "@app/products/property/system-admin/settings/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { ePathLevel } from "@common/stores/products/enum";
import {
  CCAccordion,
  CCMenuNodeDTO,
  ICCMenuItem,
  IFlatCCMenuItem,
  ProductListingDTO,
} from "@common/stores/products/model";
import { commonProductStore } from "@common/stores/products/store";
import { isEmpty, isFunction, isNil } from "lodash";

/**
 * @param products `ProductListingDTO[]` - List of active product from server
 * @param routes - `ICCRoute[]` - List route in client
 * @returns `ICCMenuItem[]` - List menu
 */
export const convertProductsToMenu = (
  products: ProductListingDTO[],
  routes: ICCRoute[],
  hiddenMenu?: ICCMenuItem[]
): ICCMenuItem[] => {
  let newProductConverted: ICCMenuItem[] = [];

  products.forEach((product) => {
    const findRoute = routes?.find((route) => {
      return route.enum === product.ProductType_ENUM;
    });
    if (!findRoute) return undefined;
    const productStaticPath = `/${findRoute.path}`;

    let newChildren: ICCMenuItem[] = [];
    if (product?.Flag_CommunityCentral) {
      if (product?.Menu?.Nodes) {
        product.Menu.Nodes.forEach((child: CCMenuNodeDTO) => {
          if (
            !child.MenuActionEnumeration_Id ||
            !child.ChildNodes ||
            child.ChildNodes.length < 1
          ) {
            return undefined;
          }

          const foundRoutes = findRoute?.children?.filter(
            (route: ICCRoute) => route.enum === child.MenuActionEnumeration_Id
          );
          if (!foundRoutes || !foundRoutes?.length) return undefined;
          if (foundRoutes?.length) {
            const menuConverter = (route: ICCRoute) =>
              convertMenu(child as CCMenuNodeDTO, route, productStaticPath);
            foundRoutes.forEach((route: ICCRoute) => {
              if (hiddenMenu && route?.isVisible === false) {
                hiddenMenu.push(menuConverter(route)); //Route for manage page or route hide in menu (only in flat menu)
              } else {
                newChildren.push(menuConverter(route)); //Route for a list and or another route as usual
              }
            });
          }
        });
      } else {
        const routerConverted = convertRouteToMenu(findRoute)?.children;
        newChildren = routerConverted ? [...routerConverted] : [];
      }
    }
    const newName =
      product?.Product_Label && !isEmpty(product.Product_Label)
        ? product?.Product_Label
        : findRoute.name ?? "";
    const newNavigationPath =
      product?.Flag_CommunityCentral === false ||
      isNil(product?.Flag_CommunityCentral)
        ? productStaticPath
        : undefined;

    const newMenuItem = {
      name: newName,
      path: productStaticPath,
      navigationPath: newNavigationPath,
      children: newChildren,
    };
    newProductConverted.push(newMenuItem);
  });
  return newProductConverted;
};

/**
 * @param router `ICCRoute` - Route config of client
 * @property {boolean|function} child.isVisible - `boolean` - Indicates if the path is visible. It can be a boolean or a function returning a boolean.
 * @returns {boolean} - True if the route is visible, false otherwise.
 * @description
 * This function determines the visibility of a route. The `isVisible` property can either be a boolean
 * or a function that returns a boolean. In some cases, we cannot directly use a variable from the MobX store
 * and need to use a function instead. This function handles both scenarios.
 */
export const isRouteVisible = (child: ICCRoute): boolean => {
  return (
    child.isVisible === false ||
    (isFunction(child.isVisible) && !child.isVisible())
  );
};

/**
 * @param router `ICCRoute` - Route config of client
 * @param parentPath - `string` - Initial parent path
 * @returns `ICCMenuItem | undefined` - The Menu Item or Undefined
 */
export const convertRouteToMenu = (
  router: ICCRoute,
  parentPath: string | undefined = ""
): ICCMenuItem | undefined => {
  const newPath = parentPath + "/" + router.path;
  if (!router.name) return undefined;

  let newChildren: ICCMenuItem[] = [];
  if (router.children) {
    router.children.forEach((child) => {
      if (isRouteVisible(child)) return;
      const newChildNode = convertRouteToMenu(child, newPath);
      if (newChildNode) newChildren.push(newChildNode);
    });
  }
  return {
    name: router.name,
    tooltip: "",
    path: newPath,
    children: newChildren,
  };
};

/**
 * @param menuNote `ICCRoute` - Menu Node from server
 * @param router - `string` - Route config of client
 * @param parentPath - `string` - Initial parent path
 * @returns `ICCMenuItem` - The Menu Item
 */
export const convertMenu = (
  menuNote: CCMenuNodeDTO,
  router: ICCRoute,
  parentPath: string | undefined = ""
): ICCMenuItem => {
  const newPath = parentPath + "/" + router.path;
  let newChildren: ICCMenuItem[] = [];

  //Get menu items from route when level path > 6
  if (getPathLevel(newPath) > 6) {
    router?.children?.forEach((child) => {
      const newMenuItem = convertRouteToMenu(child, newPath);
      if (newMenuItem) newChildren.push(newMenuItem);
    });
  } else {
    menuNote.ChildNodes?.forEach((child) => {
      if (!child.MenuActionEnumeration_Id) return undefined;
      const findRoute = router?.children?.find(
        (route) => route.enum === child.MenuActionEnumeration_Id
      );
      if (!findRoute) return undefined;
      newChildren.push(convertMenu(child, findRoute, newPath));
    });
  }

  return {
    name: menuNote.Name,
    tooltip: menuNote.ToolTip,
    path: menuNote.IsCustomisation
      ? `${newPath}?menuActionEnumId=${menuNote.MenuActionEnumeration_Id}&userViewId=${menuNote.UserView_Id}`
      : newPath,
    navigationPath: menuNote.NavigateUrl,
    children: newChildren,
    isUnnavigated: menuNote?.IsUnnavigated ?? false, // Open navigationPath the same tab or new tab
  };
};

/**
 * @param menuNode `ICCMenuItem` - Menu Node converted
 * @returns `ICCMenuItem` - First item of three level
 */
export const getFirstChild = (menuNode: ICCMenuItem): ICCMenuItem => {
  if (!menuNode.children || !menuNode.children[0]) return menuNode;
  return getFirstChild(menuNode.children[0]);
};

/**
 * @param path `ICCMenuItem` - the location pathname
 * @returns `ePathLevel` - enum of pathname (number)
 */
export const getPathLevel = (path?: string): ePathLevel => {
  if (!path) return ePathLevel.Root;
  return (path.match(/\//g) || []).length as ePathLevel;
};

/**
 * @param pathname `string` - the location pathname
 * @param level `ePathLevel` - level you want cut
 * @returns `string` - full path name with level
 */
export const getPathByLevel = (pathname: string, level: ePathLevel): string => {
  if (level === ePathLevel.Root) return "/";
  level++;
  let pathArray = pathname.split("/");
  pathArray = pathArray.slice(1, level);
  return pathArray.reduce((acc, item) => acc + "/" + item, "");
};

/**
 * @param menu `ICCMenuItem` - Menu Node converted
 * @returns `IFlatCCMenuItem[]` - Array ICCMenuItem after flat, don't have children
 */
export const flatMenu = (menu: ICCMenuItem): IFlatCCMenuItem[] => {
  if (!menu.children || !menu.children[0]) {
    menu.children = undefined;
    return [menu];
  }
  const child = menu.children?.reduce<IFlatCCMenuItem[]>(
    (array, item) => [...array, ...flatMenu(item)],
    []
  );
  menu.children = undefined;
  return [menu, ...child];
};

export const getBoolValueSetting = (settingField?: SettingField): boolean => {
  if (!settingField) return false;
  if (!settingField.Value) return false;
  if (settingField.Value === "1") return true; // Use for case save as BIT 0/1
  return settingField.Value.toLowerCase() === "true";
};
export const getNumberValueSetting = (settingField?: SettingField): number => {
  if (!settingField) return NaN;
  return parseInt(settingField.Value);
};
export const getStringValueSetting = (
  settingField?: SettingField
): string | null => {
  if (!settingField) return null;
  if (!settingField.Value) return null;
  return settingField.Value.toString();
};
export const getKeyValueBlobSetting = (
  settingField?: SettingField
): string | null => {
  if (!settingField) return null;
  if (!settingField.KeyValueBlob) return null;
  return settingField.KeyValueBlob;
};
export const getDecimalValueSetting = (settingField?: SettingField): number => {
  if (!settingField) return NaN;
  return parseFloat(settingField.Value);
};

export const filterAccessibleAccordions = (
  accordions: IPropertyPanelBar[]
): IPropertyPanelBar[] => {
  // If no accessible accordions returned (accessibleAccordions = undefined), the user has full access to all accordions
  if (!commonProductStore.accessibleAccordions) return accordions;

  // Otherwise, restrict access to only the accordions specified in accessibleAccordions
  const accessibleAccordionIds = commonProductStore.accessibleAccordions.map(
    (accessibleAccordion: CCAccordion) => accessibleAccordion.Id
  );
  return accordions.filter((accordion: IPropertyPanelBar) =>
    accessibleAccordionIds?.includes(accordion.accordionId ?? 0)
  );
};

export enum ePermissionPrivilegeTypeCheck {
  Dialog = "Dialog",
  Workflow = "Workflow",
}

export const Privilege = {
  checkPermission(
    checkType: ePermissionPrivilegeTypeCheck,
    enumPrivilege: EPrivilegesWorkflow,
    subAction?: (hasPermission?: boolean) => any
  ) {
    let hasPermission = false;
    switch (checkType) {
      case ePermissionPrivilegeTypeCheck.Dialog:
        hasPermission = !!commonProductStore.isPrivilegeDialog(enumPrivilege);
        break;
      case ePermissionPrivilegeTypeCheck.Workflow:
        hasPermission = commonProductStore.isPrivilegeWorkflow(enumPrivilege);
        break;
    }
    if (isFunction(subAction)) subAction(hasPermission);
    return hasPermission;
  },
  convertEnumWorkflowTypeToPrivilege(
    dialogEnum?: WorkflowTypes
  ): EPrivilegesWorkflow {
    return mappingPrivilege[dialogEnum as TMappingPrivilege];
  },
};

/**
 * Compares two state strings for equality, ignoring case.
 *
 * @param state1 - The first state string to compare.
 * @param state2 - The second state string to compare.
 * @returns `true` if the states are equal (case-insensitive), otherwise `false`.
 */
export const isStateEqual = (state1: string, state2: string): boolean => {
  return state1?.toUpperCase() === state2?.toUpperCase();
};
